import { Button } from '@/components/ui/button'
import Link from 'next/link'

export const CTA = () => {
  return (
    <section className="text-white bg-zinc-900 py-16 md:mx-8 px-4 md:px-0 md:rounded-xl">
      <div className="w-full md:w-5/6 mx-auto md:flex items-center ">
        <div className="md:w-2/3">
          <p className="text-2xl font-bold mb-4">Stay Ahead in the Market with Opinly</p>
          <p className="text-lg mt-4">
            Elevate your product's position or craft a unique value proposition with Opinly's
            competitor analysis software, providing the insights and tools needed to succeed in a
            competitive market.
          </p>
        </div>
        <div className="ml-auto mt-8 md:mt-0">
          <Button
            analyticsKey="stay_ahead_cta"
            className=" text-md font-semibold"
            variant={'white'}
            size={'lg'}
            analyticsProperties={{
              section: 'public_site',
            }}
          >
            <Link href="/auth/signup">Register now</Link>
          </Button>
        </div>
      </div>
    </section>
  )
}
