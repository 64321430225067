import { ghost } from '@/clients/ghost'
import Footer from '@/components/navs/Footer'
import TopNav from '@/components/navs/top-nav'
import CompetitorIntelligenceSection from '@/components/page-specific/index/11x-benefits'
import { CTA } from '@/components/page-specific/index/cta'
import FAQSection from '@/components/page-specific/index/faq'
import { HeroCenter } from '@/components/page-specific/index/hero'
import { SecurityDetails } from '@/components/page-specific/index/security'
import { KeyFeatures } from '@/components/page-specific/index/tools'
import { Metrics } from '@/components/page-specific/index/metrics'
import { GhostBlogPostIds } from '@/utils/constants'
import { AnimatePresence } from 'framer-motion'
import { Activity } from 'lucide-react'
import type { GetStaticProps, InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'
import React from 'react'
interface Props {
  post: Awaited<ReturnType<typeof ghost.posts.read>>
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const post = await ghost.posts.read({ id: GhostBlogPostIds['WHAT_IS_OPINLY'] })

  return {
    props: {
      post,
    },
  }
}

export default function Home({ post }: InferGetStaticPropsType<typeof getStaticProps>) {
  const ref = React.useRef(null)

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-zinc-50 ">
        <Activity className=" w-24 h-24 text-zinc-500  mb-8" />
        <h1 className="text-4xl font-bold text-center text-zinc-800  mb-4">
          We're down for maintenance
        </h1>
        <p className="text-lg text-center text-zinc-600  mb-8">
          We're currently updating our platform with some cool new features. We'll be back soon!
        </p>
        <div className="w-full max-w-md bg-zinc-200 rounded">
          <div
            className="h-2 bg-primary rounded"
            style={{
              width: '90%',
            }}
          />
        </div>
        <p className="text-xs text-center text-zinc-500 mt-4">Installation progress: 90%</p>
      </div>
    )
  }

  return (
    <>
      <NextSeo
        title="Opinly - Automated Competitor Analysis"
        description="Opinly automates competitor analysis and real-time data tracking of competitors. Gain strategic insights with our comprehensive competitive analysis and real-time data tracking."
        themeColor="#ffffff"
      />
      <AnimatePresence>
        {/* <Link href="/auth/login" className="block">
          <div className="bg-black text-white py-6 px-52 flex items-center justify-between cursor-pointer">
            <div className="flex items-center space-x-2">
              <span className="text-xl font-semibold">
                Introducing Opinly. Your AI Opinion Aggregator.
              </span>
            </div>
            <div className="text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </Link> */}
        <div className="md:mx-48 md:h-8 md:border-x md:border-zinc-200" />
        <div className="md:border-x md:border-b md:border-zinc-200" />
        <div className="md:mx-48 md:border-x md:border-zinc-200">
          <div className="md:border-b md:border-zinc-200 md:border-dashed">
            <TopNav />
          </div>
          <HeroCenter />
          <CompetitorIntelligenceSection />
          <Metrics />
          <KeyFeatures />
          <SecurityDetails />
          <FAQSection />
          <CTA />
          <Footer />
        </div>
      </AnimatePresence>
    </>
  )
}
