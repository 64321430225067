'use client'

import { cn } from '@/lib/utils'
import { BentoCard, BentoGrid, BentoCardProps } from '@/components/magicui/bento-grid'
import { Lock, Database, User, Clock, UsersIcon } from 'lucide-react'

const features: Omit<BentoCardProps & { id: string }, 'background'>[] = [
  {
    id: 'encryption',
    Icon: Lock,
    name: 'Data Encryption',
    description:
      'We use advanced encryption standards (AES-256) to protect your data both at rest and in transit. All communications between your browser and our servers are secured via TLS 1.2 or higher.',
    className: 'col-span-3 lg:col-span-2',
  },
  {
    id: 'backup',
    Icon: Database,
    name: 'Data Backup and Recovery',
    description:
      'We perform regular data backups and have disaster recovery plans in place to protect your data against unforeseen events.',
    className: 'col-span-3 lg:col-span-1',
  },
  {
    id: 'authentication',
    Icon: User,
    name: 'Secure Authentication',
    description:
      'We offer multi-factor authentication (MFA) and single sign-on (SSO) options to ensure only authorized users have access to your data.',
    className: 'col-span-3 lg:col-span-1',
  },
  {
    id: 'monitoring',
    Icon: Clock,
    name: '24/7 Monitoring',
    description:
      'Our systems are monitored around the clock to detect and respond to security incidents promptly. We have intrusion detection systems and regular vulnerability assessments.',
    className: 'col-span-3 lg:col-span-2',
  },
]

export function SecurityDetails() {
  return (
    <div className="p-8 mt-36">
      <h2 className="text-4xl md:text-6xl font-medium text-center mb-4">
        Security and Data Protection
      </h2>
      <p className="text-xl font-medium text-zinc-500 text-center mb-12">
        We prioritize your security and privacy.
      </p>

      <BentoGrid>
        {features.map((feature) => (
          <BentoCard key={feature.id} {...(feature as unknown as BentoCardProps)} />
        ))}
      </BentoGrid>
    </div>
  )
}

export default SecurityDetails
