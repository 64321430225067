import React from 'react'

interface MetricCardProps {
  value: string
  label: string
  subtext: string
}

const MetricCard: React.FC<MetricCardProps> = ({ value, label, subtext }) => {
  return (
    <div className="flex flex-col items-center p-4">
      <div className="text-5xl font-bold mb-2 text-center text-zinc-600">{value}</div>
      <div className="text-lg font-semibold mb-1 text-center  text-zinc-600">{label}</div>
      <div className="text-sm text-zinc-500 text-center">{subtext}</div>
    </div>
  )
}

export const Metrics: React.FC = () => {
  const metrics: MetricCardProps[] = [
    { value: '3.5x', label: 'Faster Insights', subtext: 'than traditional methods' },
    { value: '18h', label: 'Saved on Research', subtext: 'per week' },
    { value: '31%', label: 'Lower Costs', subtext: 'than traditional methods' },
    { value: '96%', label: 'User Satisfaction', subtext: 'based on feedback' },
  ]

  return (
    <div className="mx-8 mt-32 mb-8 bg-gradient-to-r from-pink-200/60 to-pink-600/20 p-6 rounded-lg shadow-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {metrics.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>
    </div>
  )
}

export default Metrics
