import Marquee from '@/components/magicui/marquee'
import { cn } from '@/lib/utils'
import Image from 'next/image'
import Link from 'next/link'

const companies = [
  { href: 'https://www.steppay.kr', src: '/files/companies/steppay.webp', name: 'Steppay' },
  { href: 'https://www.bosch.com/', src: '/files/companies/bosch.webp', name: 'Bosch' },
  { href: 'https://www.sanalabs.com/', src: '/files/companies/sanalabs.webp', name: 'Sana Labs' },
  { href: 'https://up.codes/', src: '/files/companies/upcodes.webp', name: 'UpCodes' },
  { href: 'https://www.zeni.ai/', src: '/files/companies/zeni.webp', name: 'Zeni AI' },
  {
    href: 'https://www.contractsafe.com/',
    src: '/files/companies/ContractSafe.webp',
    name: 'Contract Safe',
  },
  {
    href: 'https://www.paramountgroup.com/',
    src: '/files/companies/paramountgroup.webp',
    name: 'Paramount Group',
  },
  {
    href: 'https://www.lemonade.com/',
    src: '/files/companies/lemonade.webp',
    name: 'Lemonade',
  },
]

const CompanyCard = ({ href, src, name }: { href: string; src: string; name: string }) => {
  return (
    <Link
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(
        'relative w-32 cursor-pointer overflow-hidden rounded-xl p-2',
        'bg-zinc-950/[.01] hover:bg-zinc-950/[.05]',
        ''
      )}
    >
      <div className="flex flex-col items-center gap-2">
        <Image
          className="h-12 w-auto object-contain"
          src={src}
          alt={`${name} logo`}
          width={200}
          height={80}
          priority
        />
      </div>
    </Link>
  )
}

export const Companies = () => {
  return (
    <div className="w-full overflow-hidden">
      <p className="text-base font-semibold text-zinc-600">
        <span className="text-xl mr-1">🤝</span>Trusted by over 100 brands and agencies
      </p>
      <div className="relative flex w-full flex-col items-center justify-center overflow-hidden rounded-lg bg-background md:shadow-xl">
        <Marquee pauseOnHover className="[--duration:20s]">
          {companies.map((company) => (
            <CompanyCard key={company.src} {...company} />
          ))}
        </Marquee>

        <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white"></div>
        <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white"></div>
      </div>
    </div>
  )
}

export default Companies
