'use client'

import { AnimatedList } from '@/components/magicui/animated-list'
import NumberTicker from '@/components/magicui/number-ticker'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import { useState } from 'react'
import { File, Folder, Tree } from '@/components/magicui/file-tree'

export function FileTreeDemo() {
  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden rounded-lg">
      <Tree
        className="p-2 overflow-hidden rounded-md bg-background bg-zinc-900 text-zinc-100"
        initialSelectedId="7"
        initialExpandedItems={[
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
        ]}
        elements={ELEMENTS}
      >
        <Folder element="Competitors" value="1">
          <Folder value="2" element="Amazon">
            <File value="3">
              <p>Product Launches</p>
            </File>
            <File value="4">
              <p>Pricing Strategies</p>
            </File>
            <File value="5">
              <p>AWS Services</p>
            </File>
          </Folder>
          <Folder value="6" element="Microsoft">
            <Folder value="7" element="Cloud Services">
              <File value="8">
                <p>AI Initiatives</p>
              </File>
            </Folder>
            <Folder value="9" element="Software">
              <File value="10">
                <p>Office Suite</p>
              </File>
              <File value="11">
                <p>Windows OS</p>
              </File>
            </Folder>
          </Folder>
          <Folder value="12" element="Google">
            <File value="13">
              <p>Market Share</p>
            </File>
            <File value="14">
              <p>Partnerships</p>
            </File>
            <File value="15">
              <p>Ad Revenue</p>
            </File>
          </Folder>
          <Folder value="16" element="Apple">
            <File value="17">
              <p>Product Ecosystem</p>
            </File>
            <File value="18">
              <p>Innovation Strategy</p>
            </File>
          </Folder>
          <Folder value="19" element="Industry Trends">
            <File value="20">
              <p>Recent Acquisitions</p>
            </File>
            <File value="21">
              <p>Emerging Technologies</p>
            </File>
            <File value="22">
              <p>Market Analysis</p>
            </File>
          </Folder>
        </Folder>
      </Tree>
    </div>
  )
}

const ELEMENTS = [
  {
    id: '1',
    isSelectable: true,
    name: 'Competitors',
    children: [
      {
        id: '2',
        isSelectable: true,
        name: 'Amazon',
        children: [
          {
            id: '3',
            isSelectable: true,
            name: 'Product Launches',
          },
          {
            id: '4',
            isSelectable: true,
            name: 'Pricing Strategies',
          },
          {
            id: '5',
            isSelectable: true,
            name: 'AWS Services',
          },
        ],
      },
      {
        id: '6',
        isSelectable: true,
        name: 'Microsoft',
        children: [
          {
            id: '7',
            isSelectable: true,
            name: 'Cloud Services',
            children: [
              {
                id: '8',
                isSelectable: true,
                name: 'AI Initiatives',
              },
            ],
          },
          {
            id: '9',
            isSelectable: true,
            name: 'Software',
            children: [
              {
                id: '10',
                isSelectable: true,
                name: 'Office Suite',
              },
              {
                id: '11',
                isSelectable: true,
                name: 'Windows OS',
              },
            ],
          },
        ],
      },
      {
        id: '12',
        isSelectable: true,
        name: 'Google',
        children: [
          {
            id: '13',
            isSelectable: true,
            name: 'Market Share',
          },
          {
            id: '14',
            isSelectable: true,
            name: 'Partnerships',
          },
          {
            id: '15',
            isSelectable: true,
            name: 'Ad Revenue',
          },
        ],
      },
      {
        id: '16',
        isSelectable: true,
        name: 'Apple',
        children: [
          {
            id: '17',
            isSelectable: true,
            name: 'Product Ecosystem',
          },
          {
            id: '18',
            isSelectable: true,
            name: 'Innovation Strategy',
          },
        ],
      },
      {
        id: '19',
        isSelectable: true,
        name: 'Industry Trends',
        children: [
          {
            id: '20',
            isSelectable: true,
            name: 'Recent Acquisitions',
          },
          {
            id: '21',
            isSelectable: true,
            name: 'Emerging Technologies',
          },
          {
            id: '22',
            isSelectable: true,
            name: 'Market Analysis',
          },
        ],
      },
    ],
  },
]

interface Item {
  name: string
  description: string
  icon: string
  color: string
  time: string
}

const competitorNotifications = [
  {
    name: 'Amazon Price Drop',
    description: 'Amazon reduced AWS EC2 instance prices by 15% for t3 family',
    time: '15m ago',
    icon: '💰',
    color: '#FF9900',
  },
  {
    name: 'Microsoft Product Launch',
    description: 'Microsoft released Azure AI Studio for enterprise customers',
    time: '1h ago',
    icon: '🚀',
    color: '#00A4EF',
  },
  {
    name: 'Google Market Share',
    description: 'Google Cloud Platform market share increased by 2.5% this quarter',
    time: '3h ago',
    icon: '📈',
    color: '#4285F4',
  },
  {
    name: 'Salesforce Campaign',
    description: 'Salesforce launched "AI for Everyone" global marketing campaign',
    time: '5h ago',
    icon: '📣',
    color: '#00A1E0',
  },
  {
    name: 'Oracle Acquisition',
    description: 'Oracle acquired healthcare AI startup for $2.1 billion',
    time: '12h ago',
    icon: '🤝',
    color: '#F80000',
  },
  {
    name: 'IBM Partnership',
    description: 'IBM and SAP announce expanded partnership for generative AI solutions',
    time: '1d ago',
    icon: '🤖',
    color: '#1F70C1',
  },
]

const notifications = Array.from({ length: 10 }, () => competitorNotifications).flat()

const Notification = ({ name, description, icon, color, time }: Item) => {
  return (
    <figure
      className={cn(
        'relative mx-auto min-h-fit w-full max-w-[400px] cursor-pointer overflow-hidden rounded-2xl p-4',
        'transition-all duration-200 ease-in-out hover:scale-[103%]',
        'bg-zinc-800 text-white [box-shadow:0_0_0_1px_rgba(255,255,255,.1),0_2px_4px_rgba(0,0,0,.2),0_12px_24px_rgba(0,0,0,.2)]'
      )}
    >
      <div className="flex flex-row items-center gap-3">
        <div
          className="flex min-w-12 min-h-12 items-center justify-center rounded-2xl"
          style={{
            backgroundColor: color,
          }}
        >
          <span className="text-lg">{icon}</span>
        </div>
        <div className="flex flex-col overflow-hidden">
          <figcaption className="flex flex-row items-center whitespace-pre text-lg font-medium text-white">
            <span className="text-sm sm:text-lg">{name}</span>
            <span className="mx-1">·</span>
            <span className="text-xs text-zinc-400">{time}</span>
          </figcaption>
          <p className="text-sm font-normal text-zinc-300">{description}</p>
        </div>
      </div>
    </figure>
  )
}

const CompetitorIntelligenceSection = () => {
  const [selectedContent, setSelectedContent] = useState('accelerate')

  const data = [
    { name: 'With AI', insights: 90 },
    { name: 'Without AI', insights: 30 },
  ]

  const renderContent = () => {
    switch (selectedContent) {
      case 'accelerate':
        return (
          <div className="border border-zinc-300 p-1 rounded-xl">
            <div className="bg-zinc-900 text-white rounded-lg shadow-lg overflow-hidden">
              <AnimatedList className="h-[65vh] overflow-y-auto">
                {notifications.map((item, idx) => (
                  <Notification {...item} key={idx} />
                ))}
              </AnimatedList>
            </div>
          </div>
        )
      case 'expand':
        return (
          <div className="border border-zinc-300 p-1 rounded-xl">
            <div className="bg-black text-white p-6 rounded-lg shadow-lg flex justify-center items-center">
              <div className="relative h-[60vh] overflow-hidden flex justify-center items-center">
                <p className="whitespace-pre-wrap text-8xl font-medium tracking-tighter text-white">
                  <NumberTicker value={536} />
                </p>
              </div>
            </div>
          </div>
        )
      case 'decisions':
        return (
          <div className="border border-zinc-300 p-1 rounded-xl">
            <div className="bg-black text-white p-6 rounded-lg shadow-lg">
              <div className="relative h-[60vh] overflow-hidden">
                <FileTreeDemo />
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const renderCTAButton = (text: string) => (
    <Link href="/auth/signup" passHref>
      <button className="bg-black text-white px-6 py-2 rounded-full font-bold flex items-center w-fit text-xs">
        <span className="mr-2">{text}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </Link>
  )

  return (
    <div className="p-8 mt-36">
      <h2 className="text-4xl md:text-6xl font-medium text-center mb-4">
        Opinly drives growth 24/7
      </h2>
      <p className="text-xl font-medium text-zinc-500 text-center mb-12">
        Stay ahead of the competition without expanding your team.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="grid gap-4">
          <div
            className={`bg-white p-6 rounded-lg border hover:border-zinc-700 cursor-pointer ${
              selectedContent === 'accelerate' ? 'border-zinc-700' : ''
            }`}
            onClick={() => setSelectedContent('accelerate')}
          >
            <div className="flex flex-col justify-between">
              <div>
                <h2 className="text-2xl font-bold mb-4">No more stalking</h2>
                {selectedContent === 'accelerate' && (
                  <>
                    <p className="text-zinc-600 mb-6">
                      Stop manually tracking competitors. Our AI-powered platform automatically
                      monitors their websites, social media, and news mentions 24/7, delivering
                      real-time updates straight to your dashboard.
                    </p>
                    {renderCTAButton('Try AI Analyzer Today')}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={`bg-white p-6 rounded-lg border hover:border-zinc-700 cursor-pointer ${
              selectedContent === 'expand' ? 'border-zinc-700' : ''
            }`}
            onClick={() => setSelectedContent('expand')}
          >
            <div className="flex flex-col justify-between">
              <div>
                <h2 className="text-2xl font-bold mb-4">Reclaim 536 Hours Annually</h2>
                {selectedContent === 'expand' && (
                  <>
                    <p className="text-zinc-600 mb-6">
                      Eliminate tedious manual research. Our automated competitor analysis saves you
                      an average of 536 hours per year, freeing up your team to focus on strategic
                      decision-making and implementation.
                    </p>

                    {renderCTAButton('Explore Market Insights')}
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={`bg-white p-6 rounded-lg border hover:border-zinc-700 cursor-pointer ${
              selectedContent === 'decisions' ? 'border-zinc-700' : ''
            }`}
            onClick={() => setSelectedContent('decisions')}
          >
            <div className="flex flex-col justify-between">
              <div>
                <h2 className="text-2xl font-bold mb-4">Stay organized</h2>
                {selectedContent === 'decisions' && (
                  <>
                    <p className="text-zinc-600 mb-6">
                      Keep all your competitive intelligence in one place. Our platform organizes
                      and categorizes competitor data, market trends, and insights, making it easy
                      to access and share critical information across your organization.
                    </p>
                    {renderCTAButton('Get AI Recommendations')}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {renderContent()}
      </div>
    </div>
  )
}

export default CompetitorIntelligenceSection
