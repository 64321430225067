import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

interface ToolCardProps {
  imageSrc: string
  tag: string
  title: string
  subTitle?: string
  description: string
  linkAddress?: string
}

const ToolCard: React.FC<ToolCardProps> = ({
  imageSrc,
  tag,
  title,
  subTitle,
  description,
  linkAddress,
}) => {
  return (
    <Link
      href={linkAddress || ''}
      className="bg-zinc-100 hover:bg-zinc-200/50 mx-auto w-full rounded-lg pl-10 pt-10 flex flex-col md:flex-row items-center justify-between hover:border-zinc-600"
    >
      <div className="flex-1 mr-6 pt-0 h-full w-full">
        <p className="text-sm text-orange-500 font-extrabold">{tag}</p>
        <h3 className="font-semibold text-2xl mb-2 text-zinc-900">{title}</h3>
        {description && <p className="text-md text-zinc-900">{description}</p>}
      </div>
      <div className="mt-4 md:mt-auto pl-2 pt-2 ml-auto max-w-[500px] pl-1 pt-1 border-t-2 border-l-2 border-white rounded-tl-lg min-h-90 w-full">
        <div className="bg-zinc-50 p-2 rounded-tl-lg p-4 space-y-4">
          <p className="ml-2 text-md text-zinc-700 font-bold">{subTitle}</p>

          <Image
            className="rounded-lg p-4 bg-white w-full h-auto object-contain rounded-lg"
            priority={true}
            src={imageSrc}
            alt="Logo"
            width={1000}
            height={1000}
          />
        </div>
      </div>
    </Link>
  )
}

export default ToolCard

export const KeyFeatures = () => {
  return (
    <section className="mt-36 md:mt-24 mx-auto w-full">
      <div className="sticky px-1 w-full mx-auto p-4 md:p-12 h-fit">
        <h2 className="text-4xl md:text-6xl font-medium text-center mb-4">
          Opinly gets you data on autopilot
        </h2>
        <p className="text-xl font-medium text-zinc-500 text-center mb-12">
          Automate every step of competitor monitoring, from gathering news to tracking SEO data.
        </p>
        <div className="mt-6 font-normal pb-4 bg-clip-text text-transparent bg-gradient-to-b from-zinc-600 to-zinc-500 mx-auto grid gap-8">
          <div className="grid gap-8 w-full">
            <ToolCard
              imageSrc="/files/landing/competitive-price-analysis.svg"
              tag="Pricing"
              title="Competitive Price Analysis"
              subTitle="Pricing Insights"
              description="Analyze competitors' pricing strategies to stay ahead in the market."
              linkAddress="/tools/competitive-price-analysis"
            />

            <ToolCard
              imageSrc="/files/landing/product-comparison-tool.svg"
              tag="Comparison"
              title="Features comparison"
              subTitle="Product Insights"
              description="Compare competitor products, features, and reviews for informed decision-making."
              linkAddress="/tools/product-comparison-tool"
            />

            <ToolCard
              imageSrc="/files/landing/landing-pages.svg"
              tag="Web Analysis"
              title="Landing Pages"
              subTitle="Web Insights"
              description="Create engaging landing pages that highlight your unique selling points."
              linkAddress="/tools/competitor-landing-page-comparison"
            />

            <ToolCard
              imageSrc="/files/landing/seo-monitor.svg"
              tag="SEO"
              title="SEO Monitor"
              subTitle="Search Insights"
              description="All-in-one SEO tool with real-time insights, keyword research, and competitor analysis."
              linkAddress="/tools/seo-monitor"
            />

            <ToolCard
              imageSrc="/files/landing/competitor-email-monitoring.svg"
              tag="Email"
              title="Competitor Email Monitoring"
              subTitle="Email Insights"
              description="Track and analyze competitors' email marketing campaigns and strategies."
              linkAddress="/tools/competitor-email-monitoring"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
